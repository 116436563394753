import React , {useState,useEffect}from 'react'
import './mainpage.css'
import  Container  from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import 'bootstrap/dist/css/bootstrap.min.css';

function Privacy(){
    let [privacy, setPrivacy] =useState()
    let [privacyimg, setprivacyImg] = useState()
    let [privacydec, setprivacyDec] = useState()
    let [privacytitle, setprivacyTitle] = useState()
    useEffect(() => {
  fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=privacy-policy&ContentKey=main")
    .then(response => response.json())
  .then((data) => 
    {
      setPrivacy(data.data.contentText);
      setprivacyImg(data.data.pageContentImages[0].imageDataUrl); 
      setprivacyDec(data.data.description);
      setprivacyTitle(data.data.title);
    })
},  []) 
    return(
        <>                
                        <div id='textonimg'  className='mb-5 mt-5 pt-4' >
                            <img src= {privacyimg} alt="" id="layer"style={{ width: '-webkit-fill-available' ,height:250 }}  />
                            <div className="top text-light"><h4>{privacytitle}</h4></div>
                            <div className="top-left text-light mb-5"><h1><b>{privacydec}</b></h1></div>
                        </div>
                 
                    <Container >
                    <Row className=''>
                        <Col className='mt-5'>
                        <div   dangerouslySetInnerHTML={{__html: privacy}}></div>
                 
                        
                       </Col>
                       
                    </Row>
                    
            </Container>
        </>
    )
}
export default Privacy;