import './mainpage.css'
import './animate.css'
import React , {useState,useEffect}from 'react'
import Carousel from 'react-bootstrap/Carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ScrollAnimation from 'react-animate-on-scroll';
import quotation from '../Assets/Image/quotation.png';

function Mainpage () {
 
  //Variable declare for API
  let [banner, setcarImage] = useState( )
  let [datades, setdataDes] = useState( )
  let [playstore, setplayStore] =useState()
  let [titleapp, settitleApp] = useState()
  let [decapp, setdecApp] = useState()
  let [ios1, setIos] =useState()
  let [bike1, setBike] =useState()
  let [car1, setCar] =useState()
  let [auto1, setAuto] =useState()
  let [ccare, setcCare] = useState()
  let [ccareimg1, setccareimg1] = useState()
  let [ccust, setcCust] = useState()
  let [ccustimg1, setccustimg1] = useState()
  let [happycusttitle, sethappycusttitle] = useState()
  let [happycustdec, sethappycustdec] = useState()
  let [cmatitle, setcmatitle] = useState()
  let [cmadec, setcmadec] = useState() 
  let[backgroundcma, setbackgroundCma] = useState()
  let[cmahomedata, setcmahomedata] = useState()
  let[cCaretitle, setcCaretitle] = useState()
  let[cCusttitle, setcCusttitle] = useState()
  let[appimg, setappimg] = useState()
  let [applinkPlay, setapplinkPlay] = useState()
  let [applinkIos, setapplinkIos] = useState()
  let [crdata, setcrdata] = useState()
  //API fetch
  useEffect(() =>
  {
    fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=banner")
    .then(response => response.json())
    .then((data) => 
      {
        setcarImage(data.data.pageContentImages[0].imageDataUrl);
      })

    fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=download-app")
    .then(response => response.json())
    .then((data) => 
      {
        setplayStore(data.data.pageContentImages[0].imageDataUrl);
        settitleApp(data.data.title);
      
      })

      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=Downlode-playstore")
    .then(response => response.json())
    .then((data) => 
      {
        setapplinkPlay(data.data.description)
      })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=Downlode-ios")
    .then(response => response.json())
    .then((data) => 
      {
        setapplinkIos(data.data.description);
      })
    fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=download-app")
    .then(response => response.json())
    .then((data) => 
      {
        setIos(data.data.pageContentImages[1].imageDataUrl);
        setdecApp(data.data.description);
      
      })

    fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=bike")
    .then(response => response.json())
    .then((data) => 
      {
        setBike(data.data.pageContentImages[0].imageDataUrl);
      })

   

    fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=cab")
    .then(response => response.json())
    .then((data) => 
      {
        setCar(data.data.pageContentImages[0].imageDataUrl);
      })

    fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=auto")
    .then(response => response.json())
    .then((data) => 
      {
        setAuto(data.data.pageContentImages[0].imageDataUrl);
      })

      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=why-samaya")
      .then(response => response.json())
      .then((data) => 
        {
          setappimg(data.data.pageContentImages);
          setdataDes(data.data.description);
        })
       

    fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=ccare")
    .then(response => response.json())
    .then((data) => 
      {
        setcCaretitle(data.data.title);
        setcCare(data.data.description);
        setccareimg1(data.data.pageContentImages[0].imageDataUrl);
      })
  
    fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=cust")
    .then(response => response.json())
    .then((data) => 
      {
        setcCusttitle(data.data.title);
        setcCust(data.data.description);
        setccustimg1(data.data.pageContentImages[0].imageDataUrl);
      })
    
    fetch("https://api.samayacab.com/cms/page-contents/all?PageKey=home-customer-review")
    .then(response => response.json())
    .then((res) => 
      {
        setcrdata(res.data);
        
      })
    
        fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=happy-cust")
        .then(response => response.json())
        .then((data) => 
          {  
            sethappycusttitle(data.data.title);
            sethappycustdec(data.data.description);
          })

          fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=cma-info")
        .then(response => response.json())
        .then((data) => 
          {  
            setcmatitle(data.data.title);
            setcmadec(data.data.description);
          })

          
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=cma-background")
    .then(response => response.json())
    .then((data) => 
      {
        setbackgroundCma(data.data.pageContentImages[0].imageDataUrl);
      })
      fetch("https://api.samayacab.com/cms/page-contents/all?PageKey=home-cma-detail")
    .then(response => response.json())
    .then((data) => 
      {
        setcmahomedata(data.data);
        
      })
     
  },[]) 

  //function for OwlCarousel
  const options = 
    {
      margin: 30,
      responsiveClass: true,
      autoplay: false,
      smartSpeed: 1000,
      responsive: 
      {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 3,
        }
      },
    };

  return (
    <>
      <Container fluid className='mt-5 pt-3 '>
        <Row className='mt-5'>
          <Col sm={6} className="d-none d-sm-block">
            <h3 className='bheading1'>{titleapp}</h3>
            <h1 className='bheading2'style={{ textShadow: '2px  0px  4px  black'}}>{decapp}</h1>
            <Row className='mt-5'>
              <Col xs={12} className='mt-1'>
                <a href={applinkPlay}>
                  <img 
                    src={playstore}
                    alt=''
                    style={{ width:150 }}
                    href=''
                    className='column'
                  />
                </a>
                <a href={applinkIos}>
                  <img
                    src={ios1}
                    alt=''
                    style={{ width: 140 }}
                    className='column2'
                  />
                </a>
              </Col>
            </Row>
          </Col>
          <Col sm={6} className="d-none d-sm-block mt-1">
            <ScrollAnimation animateIn="bounceIn">
              <img src={banner} style={{ width: '100%',borderRadius: 30 ,boxShadow: 'rgb(38, 57, 77)10px 10px 15px -5px'}}/>
            </ScrollAnimation>
          </Col> 
          <Col  xs={12} className="d-block d-sm-none mt-3">
            <ScrollAnimation animateIn="bounceIn">
              <h4 className='bheading1 ml-2 text-center'>{titleapp}</h4>
              <h1  className='bheadingsmall mt-5 'style={{ textShadow: '2px  0px  4px  black' }}>{decapp} </h1>
            </ScrollAnimation>
            <Row className='mt-5 mb-5 pb-3'>
              <Col xs={12}>
                <center>
                  <a href={applinkPlay}>
                    <img 
                      src={playstore}
                      alt=''
                      style={{ width:100}}
                      href=''
                      className='column'
                    />
                  </a>
                  <a href={applinkIos}>
                  <img
                    src={ios1}
                    alt=''
                    style={{ width: 95 }}
                    className='column4'
                  />
                  </a>
                </center>
              </Col>
            </Row>
          </Col>
        </Row> 
        <Container>
          <Row className=' my-3 mt-5 pt-2' >  
            <hr className='line' style={{borderColor:'#F9C70F',borderWidth:5}}/>
            <Col md={4} sm={12} xs={12} className='my-2 typerent'>
            <ScrollAnimation animateIn="fadeInLeft">
              <Card style={{ borderRadius: 30 , borderColor:'#F9C70F',borderWidth:3 ,boxShadow: 'rgb(38, 57, 77) 10px 10px 15px -5px'}}>
                <Card.Img variant='top' src={bike1} style={{height:120 ,width:'50%'}} className='whyimg'/>
                <Card.Body >
                  <Card.Text  className='text-center'>
                   <h5 className='rent '> Rent A <b>Bike</b></h5>
                  </Card.Text>
                </Card.Body>
              </Card>
            </ScrollAnimation>
            </Col>
            <Col md={4} sm={12}  xs={12} className='my-2'>
            <ScrollAnimation animateIn="bounceIn">
              <Card style={{ borderRadius: 30 , borderColor:'#F9C70F',borderWidth:3,boxShadow: 'rgb(38, 57, 77) 10px 10px 15px -5px'}} >
                  <center><Card.Img variant='top' src={car1} style={{height:120 ,width:'70%'}} className='whyimg'/></center>
                  <Card.Body className=''>
                    <Card.Text  className='text-center'>
                      <h5 className='rent'>Rent A <b>Car</b></h5>
                    </Card.Text>
                  </Card.Body>
              </Card>
              </ScrollAnimation>
            </Col>
            <Col md={4} sm={12}  xs={12} className='my-2'>
            <ScrollAnimation animateIn="fadeInRight">
            <Card style={{ borderRadius: 30 , borderColor:'#F9C70F',borderWidth:3,boxShadow: 'rgb(38, 57, 77)10px 10px 15px -5px'}}>
                <Card.Img variant='top' src={auto1} style={{height:120 ,width:'50%'}} className='whyimg'/>
                <Card.Body className=''>
                  <Card.Text className='text-center'>
                   <h5 className='rent'> Rent An<b> Auto</b></h5>
                  </Card.Text>
                </Card.Body>
              </Card>
              </ScrollAnimation>
            </Col>
            
          </Row>
          <Row className='mt-5 ' >
           
            <Col sm={12} xs={12} className='mt-5'>
              <h2 className=''>Why <span style={{ color: '#F9C70F' }}>Samaya Cab?</span> </h2>
              <h5 className='small'>{datades}</h5>
            </Col>
          </Row>
        </Container>
          <Container className='mt-3'>
          <Carousel variant="dark"  >
              {
                appimg && appimg.map((app)=>{
                  return   <Carousel.Item ><img src={app.imageDataUrl} alt="" style={{width:'100%'}} /> </Carousel.Item>
                })
              } 
            
          </Carousel>
          </Container>
        <Container>
          <Row  className='mt-5 pt-5'>
            <Col sm={6} >   
              <ScrollAnimation animateIn="fadeInLeft">
              <img src={ccareimg1} alt="" className='mb-5' style={{ width: '100%',borderRadius: 30 ,boxShadow: 'rgb(38, 57, 77)10px 10px 15px -5px'}} />
              </ScrollAnimation>               
            </Col>
            <Col sm={6}  > 
              <div >
              <ScrollAnimation animateIn="fadeInRight">
                <h5>{cCaretitle}</h5>
                <p>{ccare}</p>
              </ScrollAnimation>  
                </div>             
            </Col>
          </Row>
              <Row  className='mt-5'>
              <Col sm={6} className='d-block d-sm-none' > 
                <ScrollAnimation animateIn="fadeInRight">
                <img src= {ccustimg1} alt="" className='mb-5' style={{ width: '100%',borderRadius: 30 ,boxShadow: 'rgb(38, 57, 77)10px 10px 15px -5px'}}  />
                </ScrollAnimation>               
              </Col>
                <Col sm={6} >   
                  <ScrollAnimation animateIn="fadeInLeft">
                    <h5>{cCusttitle}</h5>
                    <p>{ccust}</p>
                  </ScrollAnimation>               
                </Col>
                <Col sm={6} className="d-none d-sm-block" > 
                <ScrollAnimation animateIn="fadeInRight">
                <img src= {ccustimg1} alt="" className='mt-3' style={{ width: '100%',borderRadius: 30 ,boxShadow: 'rgb(38, 57, 77)10px 10px 15px -5px'}}  />
                </ScrollAnimation>               
              </Col>
         
        </Row>
        </Container>
        <Row  className='mt-5'  id='footerbg1'style={{backgroundImage:`url(${backgroundcma})`}}>
        
            <div id='transprant'>
            
             <h1 className=' transprant-text text-light m-5'><b>{cmatitle}</b></h1>
              <p className=' transprant-text1 text-light m-5' ><b>{cmadec}</b></p>
          
            </div>
         
        </Row>
      
        <Container>
          <Row>
            <Col sm={12}>
              <ScrollAnimation animateIn="fadeIn">
              <h1 className='text-center mt-5'> <span style={{ color: '#F9C70F' }}>{happycusttitle}</span></h1> 
              </ScrollAnimation>
              <p className='text-center mt-5'>{happycustdec}</p>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col xs={12}>
          <Container fluid>
            <Row >
            <OwlCarousel className="owl-theme stretch mt-5 " loop {...options}>
               {
                crdata && crdata.map((card)=>{
                  return <Card style={{ borderRadius: 15,marginTop:40,margin:25}} className='shadow-none card h-200 '>
                  <div>
                    <center>
                      <img src={quotation} alt="" style={{height:85 ,width:70,marginTop:-35}} />
                    </center>
                    <div style={{height:'150px'}}>
                      <h6 className='m-3'>{card.description}</h6>
                    </div>
                      <center>
                        <table className='pl-5 pb-4'>
                          <tr className='pl-3 mb-4'>
                            <td ><img src={card.pageContentImages[0].imageDataUrl} className='m-3'alt="" style={{height:70,width:70,borderRadius:15}} /> 
                          </td>
                            <td ><p className='m-3'> <b>{card.title}</b></p></td>  
                          </tr>  
                        </table> 
                      </center>           
                  </div>
                </Card>
                })
              } 

             </OwlCarousel>  
              </Row>   
          </Container>
          </Col>
        </Row>
        <Container  className='mt-3'>
          <Carousel variant="dark" className='' >
          {
                cmahomedata && cmahomedata.map((cmhome)=>{
                  return   <Carousel.Item >
                      <Card className='mt-5 shadow-none'>
                        <div dangerouslySetInnerHTML={{__html: cmhome.contentText}} className='m-5 text-center'></div>
                        <Row className='mt-5'>
                        <Col sm={6} ><center><img src={cmhome.pageContentImages[0].imageDataUrl} alt="" className='d-none d-sm-block prideimg ml-2 mb-5 pb-2'/></center></Col>
                          <Col sm={6} xs={12}><h3 className='prideride text-danger mt-3'>{cmhome.title}</h3>
                            <p className=' prideride mt-3 mb-3'>{cmhome.description}</p>
                          </Col>
                        </Row>
                      </Card>
                     </Carousel.Item>
                })
              } 
           
          </Carousel>
        </Container>
      </Container>
    </>
  )
}

export default Mainpage;