import React , {useState,useEffect}from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function Footer() {

    let [reachuscont, setreacusDec] = useState()
    let [copyrighttitle, setcopyrightTitle] = useState()
    let [copyrightdec, setcopyrightDec] = useState()
    let [linkedin, setlinkedin] = useState()
    let [facebook, setfacebook] = useState()
    let [insta, setinsta] = useState()
    let [twitter, settwitter] = useState()
    let [youtube, setyoutube] = useState()
    let [background, setbackground] = useState()
    let [medium, setmedium] = useState()
    useEffect(() => {
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=contact-us")
        .then(response => response.json())
        .then((data) => 
          {  
            setreacusDec(data.data.contentText)
          })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=copy-right")
        .then(response => response.json())
        .then((data) => 
          {  
            setcopyrightTitle(data.data.title)
            setcopyrightDec(data.data.description)
          })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=social-linkedin")
        .then(response => response.json())
        .then((data) => 
          {  
            setlinkedin(data.data.pageContentImages[0].imageDataUrl)
          })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=social-facebook")
        .then(response => response.json())
        .then((data) => 
          {  
            setfacebook(data.data.pageContentImages[0].imageDataUrl)
          })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=social-insta")
        .then(response => response.json())
        .then((data) => 
          {  
            setinsta(data.data.pageContentImages[0].imageDataUrl)
          })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=social-twitter")
        .then(response => response.json())
        .then((data) => 
          {  
            settwitter(data.data.pageContentImages[0].imageDataUrl)
          })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=social-youtube")
        .then(response => response.json())
        .then((data) => 
          {  
            setyoutube(data.data.pageContentImages[0].imageDataUrl)
          })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=footer-background")
        .then(response => response.json())
        .then((data) => 
          {  
            setbackground(data.data.pageContentImages[0].imageDataUrl)
          })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=logo-medium")
        .then(response => response.json())
        .then((data) => 
          {  
            setmedium(data.data.pageContentImages[0].imageDataUrl)
          })
     },[]) 
    return (
        <>
            <Container fluid className='mt-5 pt-5'>
                <Row  style={{backgroundImage:`url(${background})` }} id='footerbg' >
                    <Col sm={5}>
                        <center><Link to="/">
                            <img src={medium} alt="" 
                                style={{
                                height:150 ,
                                marginTop :50,
                                marginLeft:30,
                                marginBottom:30

                            }}/>
                        </Link></center>
                    </Col>
                    <Col sm={3}>
                        <h6 className='text-light'style={{marginTop :50,marginLeft:35}}> <b  >Discover Samaya Cab</b></h6>          
                        <ul id='list' className='text-light mt-3 demo'style={{marginLeft:35}} >
                            <li > <Link to="/About"className='text-light'style={{textDecoration:' none' }}>About Us</Link></li>
                            <li> <Link to="/Condition" className='text-light'style={{textDecoration:' none' }} >Terms & Conditions</Link></li>
                            <li> <Link to="/Privacy" className='text-light' style={{textDecoration:' none' }}> Privacy Policy</Link></li>
                            <li>  <Link to="/Refund" className='text-light' style={{textDecoration:' none' }}>Refund Policy</Link></li>       
                        </ul>
                    </Col>
                    <Col sm={3} className='mb-3'>
                      <div dangerouslySetInnerHTML={{__html: reachuscont}} className="text-light mt-5" style={{marginLeft:35}}></div>
                        
                        <a href="https://www.linkedin.com "> <img src={linkedin}  alt=""style={{height:35 ,width:35,marginLeft:35}} /></a>
                        <a href="https://www.instagram.com"><img src={insta} alt="" style={{height:38 ,width:45}}/></a>
                        <a href="https://www.twitter.com"><img src={twitter} alt=""style={{height:45 ,width:50}}/></a>
                        <a href="https://www.facebook.com"><img src={facebook} alt="" style={{height:50 ,width:40}}/></a>
                        <a href="https://www.youtube.com"><img src={youtube} alt="" style={{height:50 ,width:40}}/></a>
                    </Col>
                </Row>
                <Row className='bg-dark' style={{height:60}}>
                    <Col sm={12} xs={12}><p className=' text-light mt-3 small'>{copyrighttitle}   {copyrightdec}</p></Col>
                </Row>
            </Container>
        </>
    )
    
}

export default Footer