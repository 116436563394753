import Container from 'react-bootstrap/Container';
import React , {useState,useEffect}from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import phone from '../Assets/Image/phone.png';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

  function Navbarr() {
    let [tollnum, settollNum] = useState()
    let [small, setsmall] = useState()
    useEffect(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=toll-free")
        .then(response => response.json())
        .then((data) => 
          {  
            settollNum(data.data.title);
          })
      fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=home&ContentKey=logo-small")
        .then(response => response.json())
        .then((data) => 
          {  
            setsmall(data.data.pageContentImages[0].imageDataUrl);
          })
      },[]) 
    return (
      <>
        <Navbar expand="lg sm" bg='light' fixed="top"className='shadow'   >
          <Container fluid >
            <Navbar.Brand  >
              <Link to="/">
                <img
                  alt="SamayaLogo"
                  src={small}
                  width="60"
                  height="60"
                  style={{marginLeft:20}}                
                />{' '}
              </Link> 
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll"  />
            <Navbar.Collapse id="navbarScroll"  >
              <h2><b>SAMAYA CAB</b></h2>
              <Nav
                className=" my-2 my-lg-0 ms-auto "
                style={{ maxHeight: '120px'}}
                navbarScroll
              >
                <Nav.Link href="/" className='text-dark '>Home</Nav.Link>
                <Nav.Link><Link to="/about" className='text-dark' style={{textDecoration:' none' }}> About Us</Link></Nav.Link>
                <Button style={{ backgroundColor:"#F9C70F" ,borderRadius:20,borderColor:'#F9C70F'}} > 
                  <img
                    alt="werfg"
                    src={phone}
                    width="20"
                    height="20" 
                  />  
                  <span className='text-dark text-bold'>{tollnum}</span>
                </Button>          
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
export default Navbarr;