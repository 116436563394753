import React , {useState,useEffect}from 'react'
import './mainpage.css'
import  Container  from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css';

function Refund(){
    let [refund, setRefund] =useState()
    let [refundimg, setrefundImg] = useState()
    let [refundtitle, setrefundTilte] = useState()
    let [refunddec, setrefundDec] = useState()

    useEffect(() => {
  fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=refund-policy&ContentKey=main")
    .then(response => response.json())
  .then((data) => 
    {
      setRefund(data.data.contentText);
      setrefundImg(data.data.pageContentImages[0].imageDataUrl);
      setrefundTilte(data.data.title);
      setrefundDec(data.data.description)
    })
},  []) 
    return(
        <>
            
                <div id='textonimg'  className='mt-5 pt-4' >
                <img src= {refundimg} alt="" id="layer"style={{ width: '-webkit-fill-available' ,height:'250px' }} />
                <div className="top text-light "><h4>{refundtitle}</h4></div>
                <div className="top-left text-light"><h1><b>{refunddec}</b></h1></div>
                </div>
                <Container >
                    <Row className=''>
                        <Col className='mt-5'>
                        <div dangerouslySetInnerHTML={{__html: refund}}></div>
                 
                      
                       </Col>
                    </Row>
                    
            </Container>
        </>
    )
}
export default Refund;