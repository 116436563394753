import React , {useState,useEffect}from 'react'
import './mainpage.css'
import  Container  from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css';

function About(){
    let [aboutus, setaboutUs] =useState()
    let [aboutimg, setaboutImg] = useState()
    let [aboutdec, setaboutDec] = useState()
    let [abouttitle, setaboutTitle] = useState()
    useEffect(() => {
  fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=about-us&ContentKey=main")
    .then(response => response.json())
  .then((data) => 
    {
      setaboutUs(data.data.contentText);
      setaboutImg(data.data.pageContentImages[0].imageDataUrl);
      setaboutDec(data.data.description);
      setaboutTitle(data.data.title);
    })
},  []) 
    return(
        <>
           
                <div id='textonimg' className='mt-5 pt-4' >
                  <img src= {aboutimg} alt="" id="layer"style={{ width: '-webkit-fill-available' ,maxHeight:300 }} About />
                  <div className="top text-light"><h4>{abouttitle}</h4></div>
                  <div className="top-left text-light"><h1><b>{aboutdec}</b></h1></div>
                </div>
                <Container  >
                <Row className=''>
                    <Col className=' mt-5'>
                        <div dangerouslySetInnerHTML={{__html: aboutus}}></div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default About;