import React, { useEffect, useState } from "react";
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Components/Navbar';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './Components/Footer'
import Privacy from "./Components/Privacy"
import About from './Components/About'
import Refund from './Components/Refund'
import Condition from './Components/Condition'
import Homepage from './Components/Homepage'

import { FaArrowUp } from "react-icons/fa";

function App() {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)



  return (
    <>   
      <Router>
        <Navbar/> 
      
        <FaArrowUp className="scrollTop" onClick={scrollTop} style={{height: 40, display: showScroll ? 'flex' : 'none'}}/>
        <Routes>
       

          <Route path="/Privacy" element={<Privacy/>}/>
          <Route path="/About" element={<About/>}/>
          <Route path="/Condition" element={<Condition/>}/>
          <Route path="/Refund" element={<Refund/>}/>
          <Route path="/" element={<Homepage/>} />
        </Routes>
        <Footer/>
      </Router>

    </>
  );
}

export default App;
