import React , {useState,useEffect}from 'react'
import './mainpage.css'
import  Container  from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card  from 'react-bootstrap/Card'
import 'bootstrap/dist/css/bootstrap.min.css';

function Condition(){
    let [condition, setCondition] =useState()
    let [conditionimg, setconditionImg] = useState()
    let [conditiondec, setconditionDec] = useState()
    let [conditiontitle, setconditionTitle] = useState()
    useEffect(() => {
  fetch("https://api.samayacab.com/cms/page-contents?Website=1&PageKey=terms&ContentKey=main")
    .then(response => response.json())
  .then((data) => 
    {
      setCondition(data.data.contentText);
      setconditionImg(data.data.pageContentImages[0].imageDataUrl);
      setconditionDec(data.data.description);
      setconditionTitle(data.data.title);
    })
},  []) 
    return(
        <>
          
               
                        <div id='textonimg' className='mt-5'>
                            <img src= {conditionimg} alt="" id="layer"style={{ width: '-webkit-fill-available' ,maxHeight:300 }}  />
                            <div className="top text-light"><h4>{conditiontitle}</h4></div>
                            <div className="top-left text-light"><h1><b>{conditiondec}</b></h1></div>
                        </div>
                  
                    <Container >
                    <Row className=''>
                        <Col className='mt-5'>
                        <div dangerouslySetInnerHTML={{__html: condition}}></div>
                 
                      
                       </Col>
                    </Row>
                   
            </Container>
        </>
    )
}
export default Condition;